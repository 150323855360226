<template>
    <div>
        <el-main v-if="info" class="contentBox">
            <div class="user-info">
                <div class="title">1、技工信息</div>
                <div class="content padding between">
                    <div class="replenish">
                        <div class="subtitle">技工信息：</div>
                        <div>
                            <div class="imageSpan">
                                <div>个人头像：</div>
                                <el-image style="width: 60px; height: 60px" :src="info.avatar[0]"
                                    :preview-src-list="info.avatar"></el-image>
                            </div>
                            <span>技师姓名：{{ info.artisan_name }}</span>
                            <span>性别：{{ info.sex == 0 ? '女' : info.sex == 1 ? '男' : '--' }}</span>
                            <span>身份证号码：{{ info.id_number }}</span>
                            <span>联系电话：{{ info.mobile }}</span>
                            <span>所在地址：{{ info.address }}</span>
                            <div class="imageSpan">
                                <div>资质证书：</div>
                                <el-image style="width: 60px; height: 60px" :src="info.certification[0]"
                                    :preview-src-list="info.certification"></el-image>
                            </div>
                            <span>职称：{{ info.job_title }}</span>
                            <span>工作年限：{{ info.years_of_working }}</span>
                            <span>技师类型：{{ info.type == 1 ? '平台技工' : info.type == 2 ? '小区技工' : '入驻技工' }}</span>
                            <span v-if="info.type == 2 && info.property_community">所属物业：{{
                                info.property_community.property_name }}</span>
                            <span v-if="info.type == 2 && info.property_community">小区名称：{{
                                info.property_community.community_name }}</span>
                        </div>
                    </div>
                    <div class="replenish">
                        <div class="subtitle">用户信息：</div>
                        <div>
                            <div class="imageSpan">
                                <div>用户头像：</div>
                                <el-image style="width: 60px; height: 60px" :src="info.user.avatar[0]"
                                    :preview-src-list="info.user.avatar"></el-image>
                            </div>
                            <span>用户名称：{{ info.user.name }}</span>
                            <span>用户手机：{{ info.user.mobile }}</span>
                        </div>
                    </div>
                    <div class="replenish">
                        <div class="subtitle">服务信息：</div>
                        <div>
                            <span>服务分类：{{ info.classify_name }}</span>
                            <span>服务区域：
                                <span v-for="item in info.service_areas" :key="item">{{ item.province_name }}{{
                                    item.city_name }}{{ item.area_name }}、</span>
                            </span>
                        </div>
                    </div>
                    <div class="replenish" v-if="info.type == 3 && info.check_status != -2 && checkInfo">
                        <div class="subtitle">考核信息：</div>
                        <div>
                            <span>考核时间：{{ checkInfo.check_time }}</span>
                            <span>确定考核时间：{{ checkInfo.verify_check_time }}</span>
                            <span>考核地址：{{ checkInfo.subsidiary_name }}</span>
                            <span>考核状态：{{ checkInfo.check_status == -1 ? '待审核' : checkInfo.check_status == 1 ? '已通过' : '已驳回'
                            }}</span>
                            <span v-if="checkInfo.check_status == 2">驳回原因：{{ checkInfo.remark }}</span>
                        </div>
                    </div>
                    <div class="replenish" v-if="info.type == 3 && info.insurance_status != -2 && insuranceInfo">
                        <div class="subtitle">保险信息：</div>
                        <div>
                            <span>保险有效期：{{ insuranceInfo.start_time }} ~ {{ insuranceInfo.end_time }}</span>
                            <div class="imageSpan">
                                <div>保险照片：</div>
                                <el-image style="width: 60px; height: 60px" :src="insurance_pictures[0]"
                                    :preview-src-list="insurance_pictures"></el-image>
                            </div>
                            <span>考核状态：{{ insuranceInfo.insurance_status == -1 ? '待审核' : insuranceInfo.insurance_status == 1
                                ? '已通过' : '已驳回' }}</span>
                            <span v-if="insuranceInfo.insurance_status == 2">驳回原因：{{ insuranceInfo.remark }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-info">
                <div class="title">2、订单信息</div>
                <div class="content padding between">
                    <div class="replenish">
                        <div></div>
                        <div>
                            <span>服务订单总金额：{{ info.service_order_amount }}</span>
                            <span>服务订单总数：{{ info.service_order_sum }}</span>
                            <span>服务收益总金额：{{ info.service_total_amount }}</span>
                            <span>服务评分：{{ info.service_score }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-info">
                <div class="title">3、资产信息</div>
                <div class="content padding between">
                    <div class="replenish">
                        <div></div>
                        <div>
                            <span>总收益金额：{{ info.total_amount }}</span>
                            <span>推广奖励金额：{{ info.invite_award_amount }}</span>
                            <span v-if="info.type == 3">结算费率：{{ info.rate }}%</span>
                            <span v-if="info.type == 3">保证金额：{{ info.earnest_money }} <el-button
                                    @click="showEarnestMoneyLog = !0" style="margin-left: 10px"
                                    type="text">查看</el-button></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-info">
                <div class="title">4、提现信息</div>
                <div class="content padding between">
                    <div class="replenish">
                        <div></div>
                        <div>
                            <span>申请提现金额：{{ info.apply_withdraw_amount }}</span>
                            <span>成功提现金额：{{ info.yet_withdraw_amount }}</span>
                            <span>余额：{{ info.amount }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </el-main>
        <!-- 保证金明细 -->
        <el-dialog title="保证金明细" :visible.sync="showEarnestMoneyLog" width="54%">
            <el-table :data="earnestMoneyLog" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="id" label="明细ID" align="center"></el-table-column>
                <el-table-column prop="type" label="类型" align="center">
                    <template v-slot="{ row }">
                        <span>
                            {{ row.type == -1 ? '扣除保证金' : row.type == -2 ? '申退保证金' : '缴纳保证金' }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="amount" label="金额" align="center">
                    <template v-slot="{ row }">
                        <span>
                            {{ row.income_type == -1 ? '-' : '+' }}{{ row.amount }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="reason" label="扣除原因" align="center">
                    <template v-slot="{ row }">
                        <span>
                            {{ row.reason || '--' }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="order_no" label="缴纳订单号" align="center">
                    <template v-slot="{ row }">
                        <span>
                            {{ row.order_no || '--' }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="时间" width="200" align="center">
                    <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
                </el-table-column>
            </el-table>
            <Paging :total="log_total_number" :page="form.page" :pageNum="form.rows" @updatePageNum="updateDataLog">
            </Paging>
        </el-dialog>
    </div>
</template>

<script>
import { getDateformat } from '../../util/getDate';
import Paging from '@/components/paging';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            id: '',
            info: '',
            checkInfo: '',
            insuranceInfo: '',
            insurance_pictures: [],
            showEarnestMoneyLog: !1,
            form: {
                id: this.id,
                page: 1,
                rows: 10,
            },
            earnestMoneyLog: [],
            log_total_number: 0,
        };
    },
    created () {
        this.id = this.$route.query.id;
        this.getInfo();
        this.getCheckInfo();
        this.getInsuranceInfo();
        this.getEarnestMoneyLog();
    },
    methods: {
        getInfo () {
            this.$axios.post(this.$api.repair.artisan.info, { id: this.id })
                .then(res => {
                    if (res.code == 0) {
                        let info = res.result;
                        info.avatar = [info.avatar];
                        info.certification = [info.certification];
                        info.user.avatar = [info.user.avatar];
                        this.info = res.result;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        getCheckInfo () {
            this.$axios.post(this.$api.repair.artisan.checkInfo, { id: this.id })
                .then(res => {
                    if (res.code == 0) {
                        this.checkInfo = res.result;
                    }
                });
        },
        getInsuranceInfo () {
            this.$axios.post(this.$api.repair.artisan.insuranceInfo, { id: this.id })
                .then(res => {
                    if (res.code == 0) {
                        this.insuranceInfo = res.result;
                        this.insurance_pictures = [res.result.insurance_picture];
                    }
                });
        },
        getEarnestMoneyLog () {
            this.$axios.post(this.$api.repair.artisan.earnestMoneyLog, { id: this.id, page: this.form.page, rows: this.form.rows }).then(res => {
                if (res.code == 0) {
                    this.earnestMoneyLog = res.result.list
                    this.log_total_number = res.result.total_number
                }
            });
        },
        updateDataLog (val, status) {
            if (status == 0) {
                this.form.rows = val;
                this.getEarnestMoneyLog();
            } else {
                this.form.page = val;
                this.getEarnestMoneyLog();
            }
        },
        getDateformat (val) {
            return getDateformat(val);
        },
    },
};
</script>
<style lang="less" scoped>
.contentBox {
    padding: 50px;
}

.replenish {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.replenish>div:nth-child(1) {
    width: 8.6%;
    margin-top: 20px;
}

.replenish>div:nth-child(2) {
    width: 86%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    span {
        width: 25%;
        padding: 20px 0;
    }
}

.replenish {
    width: 100%;
}

.el-main {
    background: #fff;
    font-size: 14px;
    color: gray;

    .shenri {
        display: flex;

        span {
            margin: 0 5px;
        }
    }

    .title {
        color: #000000;
        font-size: 16px;
        line-height: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
    }

    .subtitle {
        color: #000000;
    }

    .padding {
        padding: 30px 0px 30px 0px;
    }

    .between {
        justify-content: space-between;
        padding: 20px 0px 20px 0px;

        .left {
            display: flex;
        }
    }

    .user-info {
        .content {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            p {
                height: 50px;
                line-height: 50px;
                margin-right: 64px;
            }

            p:nth-child(1) {
                display: flex;
                align-items: center;

                .el-image {
                    width: 40px;
                    height: 40px;
                    margin-right: 8px;
                }

                img {
                    width: 30px;
                    height: 30px;
                    margin-left: 10px;
                }
            }

            P:nth-child(6),
            p:nth-child(7) {
                margin: 0;
            }
        }
    }
}

.imageSpan {
    display: flex;
    align-items: center;
    padding-right: 110px;
}
</style>
